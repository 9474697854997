import { template as template_64d28706347e4f398bcad9baf1818045 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_64d28706347e4f398bcad9baf1818045(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
