import { template as template_dd52825981714d7cb686fe7b58cc07ef } from "@ember/template-compiler";
const WelcomeHeader = template_dd52825981714d7cb686fe7b58cc07ef(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
