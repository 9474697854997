import { template as template_93d591f57a2a43fcbdbc43c726b93a3b } from "@ember/template-compiler";
const FKControlMenuContainer = template_93d591f57a2a43fcbdbc43c726b93a3b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
