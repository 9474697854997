import { template as template_5a2b2fb82fd34a908369557f3c94d031 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_5a2b2fb82fd34a908369557f3c94d031(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
